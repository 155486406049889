import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledH2
} from './styles/H2.styles'

const H2 = ({ children, ...props }) => (
  <StyledH2
    as='h2'
    {...props}
  >
    {children}
  </StyledH2>
)

H2.propTypes = {
  children: PropTypes.node.isRequired
}

H2.defaultProps = {
  fontSize: [59.9]
}

export default H2
